.bannerBackground {
  height: 70vh;
  background: url("../../pics/carouselPictures/compressed/yogaManWhiteGradient.jpg");
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#yogaText {
  color: white;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 900;
  text-shadow: #828282 0px 0px 25px;
}


@media (max-width: 800px) {
  #yogaText {
    text-align: center;
    position: absolute;
    top: 60%;
    padding: 20px;
  }
  /* #backgroundText {
    width: 300px;
    margin: auto;
    display: block;
  } */
}



@media (min-width: 800px) {
  #yogaText {
    text-align: center;
    position: absolute;
    top: 50%;
    padding: 20px;
    left: 10%;
    font-size: 4rem;
    width: 700px;
  }
  /* #backgroundText {
    width: 500px;
    margin: auto;
    display: block;
  } */
}
