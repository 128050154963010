@media (max-width: 500px) {
  .carouselTextBlack {
    font-size: 1.4rem;
    font-weight: 700;
    color: black;
    text-shadow: 0.5px 0.2px 1px #ffffff;
    padding-bottom: 10px;
    text-align: left;
    letter-spacing: .5px;
  }
  .carouselTextWhite {
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
    text-shadow: 0.5px 0.2px 1px #212121;
    text-align: left;
    letter-spacing: .5px;
  }
}

@media (max-width: 737px) and (min-width : 500px){
  .carouselTextBlack {
    font-size: 2rem;
    font-weight: 600;
    color: black;
    text-shadow: 0.5px 0.2px 1px #ffffff;
    text-align: left;
    letter-spacing: 1px;
  }
  .carouselTextWhite {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    text-shadow: 0.5px 0.2px 1px #212121;
    text-align: left;
    letter-spacing: 1px;
  }
}

@media (min-width: 737px) {
  .carouselTextBlack {
    font-size: 3rem;
    font-weight: 600;
    color: black;
    text-shadow: 0.5px 0.2px 1px #ffffff;
    text-align: left;
    letter-spacing: 1px;
  }
  .carouselTextWhite {
    font-size: 3rem;
    font-weight: 600;
    color: white;
    text-shadow: 0.5px 0.2px 1px #212121;
    text-align: left;
    letter-spacing: 1px;
  }
}
