@media (min-width: 737px) {
  .carouselCaption {
    margin-bottom: 35vh;
  }
}

@media (max-width: 737px) and (min-width : 400px){
  .carouselCaption {
    margin-bottom: 30vh;
  }
}

@media (max-width: 400px) {
  .carouselCaption {
    margin-bottom: 23vh;
  }
}
